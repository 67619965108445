<template>
  <div class="price-quote-manager">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20" class="content-col">
        <h1>价格报价管理</h1>

        <!-- 显示价格报价列表 -->
        <div class="list-section">
          <h2>价格报价列表</h2>
          <el-table :data="priceQuotes" style="width: 100%" :row-style="{height:'32px'}" :cell-style="{padding:'0px'}">
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="price" label="价格" width="100"></el-table-column>
            <el-table-column prop="direction" label="方向" width="80"></el-table-column>
            <el-table-column prop="level" label="级别" width="80"></el-table-column>
            <el-table-column prop="currency" label="货币" width="80"></el-table-column>
            <el-table-column prop="target_volume" label="目标数量" width="100" align="right">
              <template v-slot="scope">
                {{ formatNumberWithCommasNoDecimals(scope.row.target_volume) }}
              </template>
            </el-table-column>
            <el-table-column prop="sort_order" label="排序" width="80"></el-table-column>
            <el-table-column prop="priority" label="优先级" width="80"></el-table-column>
            <el-table-column prop="is_active" label="是否激活" width="80">
              <template v-slot="scope">
                <el-switch v-model="scope.row.is_active" @change="handleUpdatePriceQuote(scope.row.id, { is_active: scope.row.is_active })"></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="notes" label="备注" width="200"></el-table-column>
            <el-table-column label="操作" >
              <template v-slot="scope">
                <el-button size="small" style="padding-right: 10px; color: #000;" @click="editPriceQuote(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 添加价格报价按钮 -->
        <el-button type="primary" @click="isAddDialogVisible = true">添加新价格报价</el-button>

        <!-- 添加价格报价弹窗 -->
        <el-dialog v-model="isAddDialogVisible" title="添加新价格报价">
          <el-form :model="newPriceQuote" label-width="120px">
            <el-form-item label="价格">
              <el-input v-model="newPriceQuote.price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="方向">
              <el-select v-model="newPriceQuote.direction" placeholder="选择方向">
                <el-option label="买入" value="buy"></el-option>
                <el-option label="卖出" value="sell"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="级别">
              <el-select v-model="newPriceQuote.level" placeholder="选择级别">
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 1" value="buy1"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 2" value="buy2"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 3" value="buy3"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 4" value="buy4"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 5" value="buy5"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 6" value="buy6"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 7" value="buy7"></el-option>
                <el-option v-if="newPriceQuote.direction === 'buy'" label="买入级别 8" value="buy8"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 1" value="sell1"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 2" value="sell2"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 3" value="sell3"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 4" value="sell4"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 5" value="sell5"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 6" value="sell6"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 7" value="sell7"></el-option>
                <el-option v-if="newPriceQuote.direction === 'sell'" label="卖出级别 8" value="sell8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货币">
              <el-input v-model="newPriceQuote.currency"></el-input>
            </el-form-item>
            <el-form-item label="目标数量">
              <el-input v-model="newPriceQuote.target_volume" type="number"></el-input>
            </el-form-item>
            <el-form-item label="排序">
              <el-input v-model="newPriceQuote.sort_order" type="number"></el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-input v-model="newPriceQuote.priority" type="number"></el-input>
            </el-form-item>
            <el-form-item label="是否激活">
              <el-switch v-model="newPriceQuote.is_active"></el-switch>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="newPriceQuote.notes" type="textarea"></el-input>
            </el-form-item>
            <el-button type="primary" @click="addPriceQuote">添加价格报价</el-button>
          </el-form>
        </el-dialog>

        <!-- 编辑价格报价弹窗 -->
        <el-dialog v-model="isEditDialogVisible" title="编辑价格报价">
          <el-form :model="editPriceQuoteData" label-width="120px">
            <el-form-item label="价格">
              <el-input v-model="editPriceQuoteData.price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="目标数量">
              <el-input v-model="editPriceQuoteData.target_volume" type="number" :placeholder="'请输入整数'"></el-input>
            </el-form-item>
            <el-form-item label="是否激活">
              <el-switch v-model="editPriceQuoteData.is_active"></el-switch>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editPriceQuoteData.notes" type="textarea"></el-input>
            </el-form-item>
            <el-button type="primary" @click="handleUpdatePriceQuote(editPriceQuoteData.id, editPriceQuoteData)">更新价格报价</el-button>
          </el-form>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { createPriceQuote, updatePriceQuote, getAllPriceQuotes } from '@/utils/priceQuoteService';
import { ElMessage } from 'element-plus';
import SidebarMenu from '@/components/SidebarMenu.vue'; // 引入 SidebarMenu 组件
import { formatNumberWithCommasNoDecimals } from "@/utils/formatNumber";

export default {
  components: {
    SidebarMenu // 注册 SidebarMenu 组件
  },
  name: 'PriceQuoteManager',
  setup() {
    const newPriceQuote = ref({
      price: '',
      direction: '',
      level: '',
      currency: 'USDT',
      target_volume: 0,
      sort_order: 0,
      priority: 2,
      is_active: true,
      notes: '',
    });

    const priceQuotes = ref([]);
    const isEditDialogVisible = ref(false);
    const isAddDialogVisible = ref(false); // 新增状态控制添加弹窗
    const editPriceQuoteData = ref({});

    const fetchPriceQuotes = async () => {
      try {
        const response = await getAllPriceQuotes();
        priceQuotes.value = response.data;
      } catch (error) {
        console.error('获取价格报价时出错:', error);
        ElMessage.error('获取价格报价失败');
      }
    };

    const addPriceQuote = async () => {
      try {
        await createPriceQuote(newPriceQuote.value);
        ElMessage.success('价格报价添加成功');
        fetchPriceQuotes(); // 刷新列表
        isAddDialogVisible.value = false; // 关闭添加弹窗
      } catch (error) {
        console.error('添加价格报价时出错:', error);
        ElMessage.error('添加价格报价失败');
      }
    };

    const editPriceQuote = (quote) => {
      editPriceQuoteData.value = { ...quote }; // 复制数据以便编辑
      isEditDialogVisible.value = true; // 显示编辑对话框
    };

    const handleUpdatePriceQuote = async (id, priceQuoteData) => {
      try {
        await updatePriceQuote(id, priceQuoteData);
        ElMessage.success('价格报价更新成功');
        fetchPriceQuotes(); // 刷新列表
        isEditDialogVisible.value = false; // 关闭对话框
      } catch (error) {
        console.error('更新价格报价时出错:', error);
        ElMessage.error('更新价格报价失败');
      }
    };

    onMounted(() => {
      fetchPriceQuotes();
    });

    return {
      newPriceQuote,
      priceQuotes,
      addPriceQuote,
      editPriceQuote,
      handleUpdatePriceQuote,
      formatNumberWithCommasNoDecimals,
      isEditDialogVisible,
      isAddDialogVisible, // 返回添加弹窗的状态
      editPriceQuoteData,
    };
  },
};
</script>

<style scoped>
.price-quote-manager {
  padding: 20px;
}

.form-section, .list-section {
  margin-bottom: 30px;
}

.el-table {
  margin-top: 20px;
}

.content-col {
  padding: 20px;
}
</style> 
