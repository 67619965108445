<template>
    <div class="login-page">
        <el-row justify="center" align="middle" class="full-height">
            <el-col :span="24" :offset="0">
                <el-card class="login-card">
                    <h1 class="login-title">{{ t("message.login") }}</h1>
                    <el-form :model="form" label-width="120px" @submit.prevent="login">
                        <el-form-item :label="t('message.email')">
                            <el-input v-model="form.email" type="email" placeholder="输入您的邮箱" size="large"
                                @keyup.enter="login"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :loading="loading" @click="login" size="large"
                                class="login-button">
                                {{ loading ? t("message.loading") : t("message.login") }}
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { magic } from '@/main'; // 引入已經在 main.js 中配置的 Magic
import { ElMessage } from 'element-plus';

export default {
    name: 'LoginPage',
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const userStore = useUserStore();

        const form = ref({
            email: ''
        });
        const loading = ref(false);

        const login = async () => {
            if (loading.value) return;
            loading.value = true;
            try {
                console.log('登录尝试开始');

                // 使用 Magic 登录获取 DID token
                let didToken = await magic.auth.loginWithEmailOTP({ email: form.value.email, showUI: true });
                console.log('Magic 登录 DID token:', didToken);

                
                // 使用 userStore 的 login 方法进行验证和保存用户信息
                const result = await userStore.login(form.value.email, didToken);
                console.log('登录响应:', result);

                if (result.authenticated) {
                    console.log('管理员状态:', result.isAdmin);

                    if (result.isAdmin) {
                        await router.push('/dashboard');
                    } else {
                        await router.push('/member');
                    }
                    console.log('页面跳转完成');
                } else {
                    console.error('登录失败: 用户未通过认证');
                }
            } catch (error) {
                console.error('登录失败:', error);
                if (error.name === 'NavigationDuplicated') {
                    console.log('页面已经是目标路由，无需重复跳转');
                } else {
                    console.error('发生未知错误:', error.message || '未知错误');
                    ElMessage.error('登录失败，请稍后重试');
                }
            } finally {
                loading.value = false;
                console.log('登录尝试结束');
            }
        };


        
        return {
            t,
            form,
            login,
            loading
        };
    }
};
</script>

<style scoped>
.login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-height {
    height: 100%;
}

.login-card {
    padding: 40px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    /* 確保卡片寬度為 100% */
}

.login-title {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
}

.login-button {
    width: 100%;
}
</style>