export default {
    message: {
        language: '語言',
        title: 'Rice加密貨幣找換店',
        type: '交易類型',
        buy: '買入',
        sell: '賣出',
        amount: '港幣金額',
        submit: '提交',
        amountError: '金額必須是100的倍數且至少{minAmount}港幣',
        exchangeRateError: '獲取匯率失敗，請稍後再試',
        walletAddress: '錢包地址',
        enterWalletAddress: '輸入您的錢包地址',
        chain: '鏈名稱',
        exchangeRate: '匯率',
        amountHint: '金額必須是100的倍數，最低為 {minAmount} 港幣',
        usdtAmount: 'USDT金額',
        orderNumber: '訂單號',
        paymentAddress: '付款地址',
        copyAddress: '複製地址',
        copyFailed: '複製失敗',
        addressCopied: '地址複製成功',
        reset: '重新下單',
        returnToHome: '返回首頁',
        walletAddressError: '錢包地址是必需的',
        invalidTronAddress: '無效的Tron錢包地址',
        invalidBscAddress: '無效的Binance Smart Chain (BSC)錢包地址',
        orderSubmitted: '訂提交成功！',
        submitFailed: '訂單提交失敗。',
        adjustAmount: '調整金額',
        enterUSDT: '輸入USDT金額',
        viewReceipt: '查看收據',
        pendingOrders: "待處理訂單",
        noPendingOrders: "沒有待處理訂單",
        statusPending: "待處理",
        statusCompleted: "已完成",
        statusClosed: "已關閉",
        edit: "編輯",
        markCompleted: "標記為已完成",
        closeOrder: "關閉訂單",
        resetOrder: "重置訂單",
        uploadImage: "拍照",
        receiptTitle: 'Rice 加密貨幣找換所',
        orderTime: '訂單創建日期',
        printTime: '收據打印時間',
        receiptDeclaration: '收據聲明',
        receiptDeclarationContent: '我已核實上述交易詳情和付款信息，並確認其準確性。一旦Rice商店將USDT轉移到提供的地址，任何後續的USDT變動將完全由您負責。請確保您的錢包地址安全，以避免任何潛在的資金損失。此外，請確認您完全理解並承認此購買是由您本人進行的，並且您對這一行為承擔全部責任。',
        printReceipt: '打印收據',
        orderType: "交易類型",
        buyOrder: "買入",
        sellOrder: "賣出",
        storeAddress: "商店地址",
        storeAddressContent: "香港九龍旺角彌敦道608號總統商業中心1樓150A鋪",
        sellReceiptDeclarationContent: '我已核實上述交易詳情和收款信息，並確認其準確性。我已收到應付現金，並確認USDT與現金的兌換已完全結清。請確認此交易僅由您本人在店內進行。請注意，在現場確認此交易後，Rice不對您離開場所後發生的任何問題負責。',
        startScanningError: '啟動掃描時發生錯誤',
        deviceNotFound: '未找到請求的設備',
        cameraNotSupported: '您的設備不支持相機功能',
        scanningError: '掃描過程中發生錯誤',
        startScanning: '開始掃描',
        stopScanning: '停止掃描',
        dashboard: '儀表盤',
        todayTransactions: "今日交易",
        todayProfit: "今日利潤",
        completedOrders: '已完成訂單',
        viewPendingOrders: '查看待處理訂單',
        status: '狀態',
        selectStatus: '選擇狀態',
        startTime: '開始時間',
        endTime: '結束時間',
        startDate: '開始日期',
        endDate: '結束日期',
        search: '搜索',
        settings: '設置',
        buyPrice: '買入價',
        sellPrice: '賣出價',
        setQuotes: '設置報價',
        save: '保存',
        memberLogin: '會員登錄',
        email: '電子郵件',
        password: '密碼',
        login: '登錄',
        memberPage: '會員頁面',
        welcome: '歡迎',
        sendCode: '發送驗證碼',
        verificationCode: '驗證碼',
        buyUSDT: '購買USDT',
        hkdToUSDT: '港幣 兌換 USDT',
        orderManagement: '訂單管理',
        saveSuccess: '保存成功',
        noPermission: '您沒有權限執行此操作',
        operationFailed: '操作失敗，請稍後再試',
        logout: '登出',
        loading: '加載中...',
        receiveAddress: '收款地址',
        createdAt: '創建日期',
        fee: '手續費',
        transactionHash: '交易哈希',
        remark: '備註',
        sendAddress: '發送地址',
        all: '全部',
        amountInput: '請輸入USDT金額或港幣金額',
        sellUSDT: '賣出USDT',
        USDTToHKD: 'USDT 兌換 港幣',
        hkdFee: '港幣手續費',
        feeApplied: "訂單金額低於 {amount} HKD，將收取 {fee} HKD 手續費",
        binanceOnlineOrder: '幣安線上訂單',
        feeHKD: '港幣手續費',
        finance: '財務',
        addFinanceRecord: '新增財務記錄',
        revenue: '收入',
        expenses: '支出',
        profit: '利潤',
        undistributedProfit: '未分配利潤',
        typeFinance: '類型',
        categoryFinance: '類別',
        subcategoryFinance: '子類別',
        projectFinance: '項目',
        remarksFinance: '備註',
        todayHKDBuy: '今日港幣買入',
        todayHKDSell: '今日港幣賣出',
        profitRate: '利潤率',
        loadingDashboardData: '加載儀表板數據中...',
        empty: '關閉',
        out: '離開',
        promptStatus: '提示狀態',
        otherInfo: '其他信息',
        outMessage: '離開信息',
        selectPromptStatus: '請選擇提示狀態',
        monthProfit: '本月利潤',
        viewPrice: '用戶互動螢幕',
        busyMessage: '有客信息',
        closedMessage: '休息信息',
        openMessage: '營業信息',
        financialAccounts: '財務賬戶',
        rmbAmount: '人民幣金額',
        cancel: '取消',
        enterPlatformOrGasFee: '請輸入平台手續費或Gas費用',
        enterHKDFee: '請輸入港幣手續費',
        platformGasFeeUSDT: '平台/Gas費用 (USDT)',
        chainType: '鏈類型',
        sendAddressType: '發送地址類型',
        editOrder: '編輯訂單',
        receiveAddressType: '接收地址類型',
        selectReceiveAddressType: '請選擇接收地址類型',
        exchange: '交易所',
        wallet: '錢包',
        selectSendAddress: '請選擇發送地址',
        paymentChannelReceiveAddress: '支付通道接收地址',
        enterUSDTAmount: '請輸入USDT金額',
        enterHKDAmount: '請輸入港幣金額',
        enterRateValue: '請輸入匯率值',
        editSuccess: '編輯成功',
        buyPriceLarge: '大額買入價',
        sellPriceLarge: '大額賣出價',
        largeOrderThreshold: '大額訂單閾值',
        annualizedReturn: '年化回報率',
        accountTransfer: '賬戶轉賬',
        frequentlyUsed: '常用',
        sourceAccount: '來源賬戶',
        targetAccount: '目標賬戶',
        hkdAmount: '港幣金額',
        confirm: '確認',
        accountTransferSuccess: '賬戶轉賬成功',
        orderAmountNotMultipleOfHundred: '訂單金額不是100的倍數',
        orderAmountError: '訂單最小金額為 {amount} 港幣',
        usdtOrHkdAction: '輸入訂單金額',
        largeOrderMessage: '訂單金額達到 {amount} HKD，享受大額價格 {rate} HKD/USDT',
        viewImage: '查看圖片',
        close: '關閉',
        rotateImage: '旋轉180度',
        selfServiceOrder: '自助下單',
        priceQuoteManager: '價格報價管理',
        enterMemberId: '請輸入會員ID',
        memberId: '會員ID',
    },
    menu: {
        dashboard: '儀表板',
        setQuotes: '設置報價',
        price: '價格'
    },
    price: {
        buyUSDT: '買入USDT',
        hkdToUSDT: '港幣 兌換 USDT',
        sellUSDT: '賣出USDT',
        USDTToHKD: 'USDT 兌換 港幣',
        minOrderAmount: '訂單最小金額: {amount}',
        feeApplied: '訂單金額低於 {amount} HKD，將收取 {fee} HKD 手續費',
        largeOrderThreshold: '大額',
        receiveUsdtAddressQrCode: '接收USDT的地址二維碼',
        chainName: '鏈名稱',
        receiveUsdtAmount: '接收USDT金額',
        orderDetails: '訂單詳情',
        orderNumber: '訂單號',
        orderType: '訂單類型',
        customerReceiveHkd: '客戶收港幣',
        customerReceiveUsdt: '客戶收USDT',
        largeOrderMessage: '訂單金額達到 {amount} HKD，享受大額價格 {rate} HKD/USDT',
        printReceipt: '打印收據',
        fullscreen: '全屏',
        shopName: 'Rice 加密貨幣找換所',
        home: '首頁',
        buy: '買入',
        sell: '賣出',
        open: '正在營業',
        busy: '有客在店',
        out: '臨時外出',
        closed: '休息放假',
        noFeeAbove500: '賣出USDT金額超過500HKD，免收手續費',
        hkdAmount: '港幣金額',
        exchangeRate: '匯率',
        fee: '手續費',
        amount: '金額',
        createdTime: '時間',
        printRecordAddedSuccess: '打印記錄添加成功',
        printRecordAddFailed: '添加打印記錄失敗',
        usdtAmount: 'USDT金額',
        targetVolume: '交易量',
        level: '等級', 
        price: '價格',
    }
};
