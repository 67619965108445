<template>
  <div v-if="!isSubmitted" class="sell-page">
    <div style="text-align: center; margin-bottom: 0px">
      <h2>
        {{ $t("message.sellUSDT") }}
      </h2>
      <div style="display: flex; align-items: center; justify-content: center;margin-bottom: 10px; margin-top: 0px">
        <img src="@/assets/usdt_logo.svg" alt="USDT" class="button-icon" />
        <el-text class="mx-1" type="info" style="margin-bottom: 0px; margin-top: 0px">{{ $t("message.USDTToHKD")
          }}</el-text>
        <img src="@/assets/hkd.png" alt="HKD" class="button-icon" style="margin-left: 10px;" />
      </div>
    </div>

    <div class="exchange-info">
      <div class="info-row">
        <span class="info-value usdt-value">
          {{ formatNumberWithCommas(form.usdtAmount).split('.')[0] }}
          <span class="decimal-value">.{{ formatNumberWithCommas(form.usdtAmount).split('.')[1] }}</span>
        </span>
        <span class="info-label usdt-label">USDT</span>
      </div>
      <div class="info-row">
        <span class="info-label">{{ $t("message.exchangeRate") }}</span>
        <span class="info-value">{{ form.exchangeRate }} HKD/USDT</span>
      </div>
      <div class="info-row">
        <span class="info-value hkd-value">{{ formatNumberWithCommasNoDecimals(form.amount) }}</span>
        <span class="info-label hkd-label">HKD</span>
      </div>
    </div>

    <el-alert v-if="form.amount / 1 < minAmount"
      :title="`${$t('message.orderAmountError')} (${minAmount} HKD) ${form.amount} HKD`" type="error" show-icon
      class="error-message" :closable="false" />
    <el-alert v-if="form.amount % 100 !== 0" :title="$t('message.orderAmountNotMultipleOfHundred')" type="warning"
      show-icon class="warning-message custom-alert" :close-text="$t('message.adjustAmount')" @close="adjustHKD" />

    <!-- 新增的按钮 -->
    <div class="button-group">
      <el-button type="success" class="usdt-button" @click="openUsdtDrawer" round>
        {{ $t("message.usdtOrHkdAction") }}
      </el-button>
    </div>

    <el-form-item>
      <div style="text-align: left;">{{ $t('message.walletAddress') }}</div>
      <el-select v-model="form.walletAddress" @change="handleChainChange" :placeholder="$t('message.selectWallet')">
        <el-option v-for="option in chainOptions" :key="option.value" :label="option.label"
          :value="option.address"></el-option>
      </el-select>
    </el-form-item>



    <el-text type="info" size="small">{{ $t('message.largeOrderMessage', {
      amount:
        formatNumberWithCommasNoDecimals(largeOrderThreshold), rate: largeExchangeRate
    }) }}</el-text>

    <div style="text-align: left;">
      <el-tag type="info" style="text-align: left;" v-if="!allowAdjust">
        {{ $t('message.selfServiceOrder') }}
      </el-tag>
    </div>

    <!-- USDT 和 HKD 输入的 Drawer -->
    <el-drawer :title="$t('message.usdtOrHkdAction')" v-model="usdtDrawerVisible" direction="btt" size="40%"
      @open="focusUsdtInput">
      <el-form label-width="100px">
        <el-form-item :label="$t('message.usdtAmount')">
          <el-input ref="usdtInputRef" v-model="usdtInput" :placeholder="$t('message.enterUSDT')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.amount')">
          <el-input v-model="hkdInput" :placeholder="$t('message.amount')"></el-input>
        </el-form-item>
        <el-button type="success" @click="adjustHKD" style="width: 100%">
          {{ t("message.adjustAmount") }}
        </el-button>
      </el-form>
    </el-drawer>



    <div class="submit-section">

      <el-button type="primary" @click="submitForm" class="submit-button" size="large">{{
        $t("message.submit")
        }}</el-button>

    </div>


  </div>

  <!-- 提交后的顯示部分 -->
  <div v-else class="submission-result">
    <div class="result-row">
      <span class="result-label">{{ t("message.orderNumber") }}:</span>
      <span class="result-value">{{ orderNumber }}</span>
    </div>
    <div class="result-row">
      <span class="result-label">{{ t("message.usdtAmount") }}:</span>
      <span class="result-value highlight">{{ formatNumberWithCommas(form.usdtAmount) }} USDT</span>
    </div>
    <div class="result-row">
      <span class="result-label">{{ t("message.exchangeRate") }}:</span>
      <span class="result-value">{{ exchangeRate }} HKD/USDT</span>
    </div>
    <div class="result-row">
      <span class="result-label">{{ t("message.amount") }}:</span>
      <span class="result-value highlight">{{ formatNumberWithCommasNoDecimals(form.amount) }} HKD</span>
    </div>
    <div class="result-row">
      <span class="result-label">{{ t("message.walletAddress") }}:</span>
      <span class="result-value">{{ form.walletAddress }}</span>
    </div>
    <qrcode-vue :value="form.walletAddress" :size="200"></qrcode-vue>
    <p>
      <strong>{{ t("message.receiptDeclaration") }}:</strong>
    </p>
    <p>{{ t("message.sellReceiptDeclarationContent") }}</p>
    <div class="button-group">
      <el-button type="primary" @click="copyAddress">{{ t("message.copyAddress") }}</el-button>
      <el-button type="primary" @click="viewReceipt">{{ t("message.viewReceipt") }}</el-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import QrcodeVue from "vue-qrcode";

import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { API_BASE_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import { useRouter } from "vue-router"; // 導入 useRouter
import { getAllPriceQuotes } from '@/utils/priceQuoteService';


export default {
  components: {
    QrcodeVue,
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter(); // 使用 useRouter
    const { t } = useI18n();

    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 1000);

    const form = ref({
      amount: "",
      usdtAmount: "",
      walletAddress: "",
      chain: "tron",
      hkdFee: "0",
    });

    const usdtInput = ref("");
    const hkdInput = ref("");

    const exchangeRate = ref(null);
    const largeExchangeRate = ref(0); // 新大额卖出价
    const largeOrderThreshold = ref(0); // 新增：大额订单阈值
    const isSubmitted = ref(false);
    const orderNumber = ref("");


    const chainOptions = ref([]);

    const usdtDrawerVisible = ref(false);

    const usdtInputRef = ref(null); // 创建一个 ref

    const focusUsdtInput = () => {
      if (usdtInputRef.value) {
        usdtInputRef.value.focus(); // 聚焦到 USDT 输入框
      }
    };

    const calculateHKD = () => {
      if (usdtInput.value && form.value.exchangeRate) {
        const hkdAmount = parseFloat(usdtInput.value) * parseFloat(form.value.exchangeRate);
        form.value.amount = hkdAmount.toFixed(0);
        form.value.usdtAmount = usdtInput.value;
      }
    };

    watch(usdtInput, (newValue) => {
      if (newValue && form.value.exchangeRate) {
        let hkdAmount = parseFloat(newValue) * parseFloat(form.value.exchangeRate);

        // 根据计算出的港币金额查找对应档位的价格
        const matchingQuote = sellQuotes.value.find(quote =>
          hkdAmount >= quote.target_volume && quote.is_active
        );

        console.log("matchingQuote:", matchingQuote);
        console.log("hkdAmount:", hkdAmount);
        console.log('sellQuotes:', sellQuotes.value);

        if (matchingQuote) {
          form.value.exchangeRate = matchingQuote.price;
          hkdAmount = parseFloat(newValue) * parseFloat(matchingQuote.price);
          console.log(`切换到卖出档位 ${matchingQuote.level}, 价格: ${matchingQuote.price}`);
        }

        form.value.amount = hkdAmount.toFixed(0);
        form.value.usdtAmount = newValue;
        hkdInput.value = "";
      }
    });

    watch(hkdInput, (newValue) => {
      const amount = parseFloat(newValue);
      if (!isNaN(amount)) {
        // 根据金额查找对应档位的价格
        const matchingQuote = sellQuotes.value.find(quote =>
          amount >= quote.target_volume && quote.is_active
        );

        if (matchingQuote) {
          form.value.exchangeRate = matchingQuote.price;
          console.log(`切换到卖出档位 ${matchingQuote.level}, 价格: ${matchingQuote.price}`);
        } else {
          // 如果没有找到匹配的档位，使用默认的第一档价格
          form.value.exchangeRate = sellQuotes.value[0]?.price || exchangeRate.value;
        }

        form.value.amount = amount.toFixed(0);
        usdtInput.value = "";
        calculateUSDT();
      }
    });

    const calculateUSDT = () => {
      if (form.value.exchangeRate && form.value.amount) {
        const usdtAmount = parseFloat(form.value.amount) / parseFloat(form.value.exchangeRate);
        form.value.usdtAmount = usdtAmount.toFixed(4);
      } else {
        form.value.usdtAmount = "";
      }
    };


    const adjustHKD = () => {
      if (form.value.amount) {
        // 将港币金额向下取整为100的倍数
        const adjustedHKD = Math.floor(parseFloat(form.value.amount) / 100) * 100;
        form.value.amount = adjustedHKD.toString();

        // 根据调整后的港币金额计算USDT金额
        if (form.value.exchangeRate) {
          const usdtAmount = adjustedHKD / parseFloat(form.value.exchangeRate);
          form.value.usdtAmount = usdtAmount.toFixed(4);
          usdtInput.value = usdtAmount.toFixed(4);
        }
      }
    };


    const sellQuotes = ref([]); // 存储卖出档位的价格列表

    // 获取价格列表
    const fetchPriceQuotes = async () => {
      try {
        const response = await getAllPriceQuotes();
        // 过滤出卖出档位的价格，并按 sort_order 排序
        sellQuotes.value = response.data
          .filter(quote => quote.direction === 'sell')
          .sort((a, b) => a.sort_order - b.sort_order);

        // 初始化默认汇率（使用第一档价格）
        if (sellQuotes.value.length > 0) {
          form.value.exchangeRate = sellQuotes.value[sellQuotes.value.length - 1].price;
          exchangeRate.value = sellQuotes.value[sellQuotes.value.length - 1].price;
        }
      } catch (error) {
        console.error('Error fetching price quotes:', error);
        ElMessage.error(t("message.fetchPriceError"));
      }
    };

    const fetchAssetsAddresses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/assets_addresses`);
        console.log('Assets Addresses:', response.data);
        chainOptions.value = response.data.data.map(item => ({
          label: `${item.chain_full_name} / ${item.address}`,
          value: item.chain_full_name,
          chain: item.chain_short_name,
          address: item.address
        }));

        if (chainOptions.value.length > 0) {
          form.value.chain = chainOptions.value[0].chain;
          handleChainChange(chainOptions.value[0].address);
        }
      } catch (error) {
        console.error("Error fetching assets addresses:", error);
        ElMessage.error(t("message.fetchAddressesError"));
      }
    };

    const handleChainChange = (value) => {
      console.log("handleChainChange:", value);
      const selectedChain = chainOptions.value.find(option => option.address === value);
      if (selectedChain) {
        form.value.chain = selectedChain.chain;
        form.value.walletAddress = selectedChain.address;
        console.log("selectedChain:", selectedChain);
      }
    };

    const validateForm = () => {
      const amount = parseFloat(form.value.amount);

      if (isNaN(amount) || amount < minAmount.value ) {
        ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
        return false;
      }

      // 验证港币金额是否等于USDT金额 * 当前汇率
      const calculatedHKD = parseFloat(form.value.usdtAmount) * parseFloat(form.value.exchangeRate);
      if (Math.abs(calculatedHKD - amount) > 0.001) {
        ElMessage.error(t("message.amountMismatchError"));
        return false;
      }

      return true;
    };

    const increaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) + 0.001).toFixed(4); // 每次增加 0.001
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) - 0.001).toFixed(4); // 每次减少 0.001
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm()) return;

      orderNumber.value = generateOrderId();

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", form.value.usdtAmount);
      formData.append("exchange_rate", form.value.exchangeRate);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "sell");
      formData.append("order_source", props.allowAdjust ? 'in_store' : 'self_service');

      console.log("formData:", Object.fromEntries(formData));

      try {
        await axios.post(`${API_BASE_URL}/preorder`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("订单提交失败:", error);
        ElMessage.error(t("message.submitFailed"));
      }

    };

    const resetForm = () => {
      form.value = {
        amount: "",
        usdtAmount: "",
        walletAddress: "",
        chain: "tron",
      };
      isSubmitted.value = false;
    };

    const copyAddress = () => {
      navigator.clipboard.writeText(form.value.walletAddress);
      ElMessage.success(t("message.addressCopied"));
    };

    onMounted(async () => {
      console.log("props:", props.allowAdjust);
      await fetchPriceQuotes();
      await fetchAssetsAddresses();
    });

    const viewReceipt = () => {
      router.push({
        name: "ReceiptSell",
        params: {
          orderNumber: orderNumber.value, // 将订单号传递到收据页面
        },
      });
    };

    const openUsdtDrawer = () => {
      usdtDrawerVisible.value = true;
    };

    return {
      form,
      usdtInput,
      hkdInput,
      exchangeRate,
      isSubmitted,
      orderNumber,
      calculateUSDT,
      calculateHKD,
      adjustHKD,
      submitForm,
      handleChainChange,
      resetForm,
      t,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals,
      viewReceipt,
      minAmount,
      increaseExchangeRate,
      decreaseExchangeRate,
      chainOptions,
      largeExchangeRate,
      largeOrderThreshold,
      usdtDrawerVisible,
      openUsdtDrawer,
      usdtInputRef,
      focusUsdtInput,
      copyAddress,
    };
  },
};
</script>

<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.success-message {
  color: green;
  margin-top: 5px;
  font-size: 12px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
  max-width: 600px;
  /* 设置最大的宽度 */
  margin-left: auto;
  /* 居中对齐 */
  margin-right: auto;
  /* 居中对齐 */
}

.submission-result p {
  font-size: 16px;
  margin-bottom: 10px;
}

.el-button {
  margin-left: 10px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.sell-page {
  margin: 20px;
  max-width: 600px;
  /* 设置最大的宽度 */
  margin-left: auto;
  /* 居中对齐 */
  margin-right: auto;
  /* 居中对齐 */
  margin-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.submit-section .el-button {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  margin-left: 0px;
  margin-right: 0px;
}


.button-group {
  display: flex;
  justify-content: center;
  /* 将按钮居中 */
  margin-top: 20px;
}

.el-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  /* 调整宽度以适应内容 */
}

.button-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.warning-message {
  color: orange;
  margin-top: 5px;
  font-size: 12px;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.success-message {
  color: green;
  margin-top: 5px;
  font-size: 12px;
}

.custom-alert .el-alert__closebtn {
  color: blue;
  /* 将关闭按钮的颜色设置为蓝色 */
  font-weight: bold;
  /* 使文字加粗 */
  cursor: pointer;
  /* 鼠标悬停时显示为手型 */
}

.submit-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  /* 确保按钮与页面等宽 */
  height: 50px !important;
  /* 使用 !important 确保样式生效 */
  font-size: 22px;
  /* 调整字体大小 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #409eff;
  /* 按钮背景色 */
  color: white;
  /* 按钮文字颜色 */
  border: none;
  border-radius: 0;
  /* 去掉圆角 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  /* 添加阴影 */
  z-index: 1000;
  /* 确保按钮在最上层 */
  margin: 0;
  /* 确保没有额外的外边距 */
}

.submission-result {
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.result-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.result-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.result-value {
  font-size: 16px;
  color: #666;
}

.result-value.highlight {
  font-size: 24px;
  /* 调大字体 */
  font-weight: bold;
  color: #000;
}

.button-group {
  display: flex;
  justify-content: space-around;
  /* 重新排版按钮 */
  margin-top: 20px;
}

.el-button {
  margin: 0 5px;
  flex: 1;
  /* 使按钮均匀分布 */
  max-width: 150px;
  /* 限制按钮最大宽度 */
}
</style>
