<template>
  <div class="mobile-price-view" style="height: calc(100vh - 70px); overflow-y: auto;">
    <h2 class="title" style="font-size: 20px; padding: 3px;margin:0;">{{ $t('price.buyUSDT') }}</h2>
    <div class="table-container" style="margin-bottom: 3px;">
      <el-table :data="buyQuotes" style="width: 100%">
        <el-table-column prop="level" label="Level" width="60"></el-table-column>
        <el-table-column prop="price" label="Price (HKD)" width="120">
          <template v-slot="scope">
            {{ formatPrice(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column prop="target_volume" label="Target Volume (HKD)">
          <template v-slot="scope">
            {{ formatNumberWithCommasNoDecimals(scope.row.target_volume) }} {{ formatVolume(scope.row.target_volume) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <h2 class="title" style="font-size: 20px; padding: 3px;margin:0;">{{ $t('price.sellUSDT') }}</h2>
    <div class="table-container" style="margin-bottom: 3px;">
      <el-table :data="sellQuotes" style="width: 100%">
        <el-table-column prop="level" label="Level" width="60"></el-table-column>
        <el-table-column prop="price" label="Price (HKD)" width="120">
          <template v-slot="scope">
            {{ formatPrice(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column prop="target_volume" label="Target Volume (HKD)">
          <template v-slot="scope">
            {{ formatNumberWithCommasNoDecimals(scope.row.target_volume) }} {{ formatVolume(scope.row.target_volume) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="current-date" style="font-size: 12px; margin-top: 5px;">
      {{ currentDate }}
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getAllPriceQuotes } from '@/utils/priceQuoteService';
import { formatNumberWithCommasNoDecimals } from "@/utils/formatNumber";

const buyQuotes = ref([]);
const sellQuotes = ref([]);
const currentDate = ref('');

const fetchPriceQuotes = async () => {
  try {
    const response = await getAllPriceQuotes();
    buyQuotes.value = response.data.filter(quote => quote.direction === 'buy');
    sellQuotes.value = response.data.filter(quote => quote.direction === 'sell');
  } catch (error) {
    console.error('Error fetching price quotes:', error);
  }
};

const formatPrice = (price) => {
  return Number(price).toFixed(4);
};

const formatVolume = (volume) => {
  if (volume >= 10000) {
    return `（${(volume / 10000).toFixed(0)}萬）`;
  } else {
    return '';
  }
};

const updateCurrentDate = () => {
  const now = new Date();
  currentDate.value = now.toLocaleString('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

onMounted(() => {
  fetchPriceQuotes();
  updateCurrentDate();
});
</script>

<style scoped>
.mobile-price-view {
  padding: 0px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #007BFF, #00C6FF);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.table-container {
  margin-bottom: 3px;
}

.el-table {
  border: 0px solid #dcdcdc;
}

.el-table th {
  background-color: #007BFF; /* 表头背景色 */
  color: white; /* 表头文字颜色 */
  font-size: 12px;
  text-align: center;
  padding: 1px; /* 调整表头行高 */
}

.el-table td {
  font-size: 14px;
  text-align: center;
  color: #ccc; /* 单元格文字颜色 */
  padding: 3px; /* 确保表头和单元格的填充一致 */
}

.el-table th, .el-table td {
  padding: 3px; /* 确保表头和单元格的填充一致 */
}

.current-date {
  text-align: center;
  margin-top: 3px;
  color: #ccc; /* 日期文字颜色 */
}
</style> 