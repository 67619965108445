<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-vertical-demo"
    @select="handleSelect"
  >
    <el-menu-item index="/dashboard">
      <el-icon><Odometer /></el-icon>
      <span>{{ t('message.dashboard') }}</span>
    </el-menu-item>
    <el-menu-item index="/pending-orders">
      <el-icon><Document /></el-icon>
      <span>{{ t('message.orderManagement') }}</span>
    </el-menu-item>
    <el-menu-item index="/set-quotes">
      <el-icon><Setting /></el-icon>
      <span>{{ t('message.setQuotes') }}</span>
    </el-menu-item>
    <el-menu-item index="/finance">
      <el-icon><Odometer /></el-icon>
      <span>{{ t('message.finance') }}</span>
    </el-menu-item>
    <el-menu-item index="/price" @click="openPriceInNewWindow">
      <el-icon><PriceTag /></el-icon>
      <span>{{ t('message.viewPrice') }}</span>
    </el-menu-item>
    <el-menu-item index="/financial-accounts">
      <el-icon><Odometer /></el-icon>
      <span>{{ t('message.financialAccounts') }}</span>
    </el-menu-item>
    <el-menu-item index="/price-quote-manager">
      <el-icon><Setting /></el-icon>
      <span>{{ t('message.priceQuoteManager') }}</span>
    </el-menu-item>
    <el-menu-item index="/logout" @click="logout">
      <el-icon><SwitchButton /></el-icon>
      <span>{{ t('message.logout') }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import { Document, Odometer, Setting, SwitchButton, PriceTag } from '@element-plus/icons-vue'

export default {
  components: {
    Document,
    Odometer,
    Setting,
    SwitchButton,
    PriceTag
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const userStore = useUserStore()

    const activeIndex = ref(route.path)

    const handleSelect = (key) => {
      if (key !== '/logout' && key !== '/price') {
        router.push(key)
      }
    }

    const logout = () => {
      userStore.clearUserData()
      router.push('/login')
    }

    onMounted(() => {
      activeIndex.value = route.path
    })

    const openPriceInNewWindow = () => {
      window.open('/price', '_blank')
    }

    return {
      t,
      activeIndex,
      handleSelect,
      logout,
      openPriceInNewWindow
    }
  }
}
</script><style scoped>
.el-menu-vertical-demo {
  height: 100vh;
}
</style>

