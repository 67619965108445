<template>
  <div class="buy-page" v-if="!isSubmitted">

    <div style="text-align: center; margin-bottom: 20px;">
      <h2>{{ $t('message.buyUSDT') }}</h2>

      <div style="display: flex; align-items: center; justify-content: center;margin-bottom: 10px; margin-top: 0px">
        <img src="@/assets/hkd.png" alt="HKD" class="button-icon" style="margin-left: 10px;"/>
        <el-text class="mx-1" type="info" style="margin-bottom: 0px; margin-top: 0px">{{ $t("message.hkdToUSDT") }}</el-text>
        <img src="@/assets/usdt_logo.svg" alt="USDT" class="button-icon" style="margin-left: 10px;" />
      </div>
    </div>

    <div class="exchange-info">
      <div class="info-row">
        <span class="info-value hkd-value">{{ formatNumberWithCommasNoDecimals(form.amount) }}</span>
        <span class="info-label hkd-label">HKD</span>
      </div>
      <div class="info-row">
        <span class="info-label">{{ $t("message.exchangeRate") }}</span>
        <span class="info-value">{{ form.exchangeRate }} HKD/USDT</span>
      </div>
      <div class="info-row">
        <span class="info-value usdt-value">
          {{ formatNumberWithCommas(form.usdtAmount).split('.')[0] }}
          <span class="decimal-value">.{{ formatNumberWithCommas(form.usdtAmount).split('.')[1] }}</span>
        </span>
        <span class="info-label usdt-label">USDT</span>
      </div>
    </div>

    <el-alert 
      v-if="form.amount /1  < minAmount" 
      :title="`${$t('message.orderAmountError')} (${minAmount} HKD) ${form.amount} HKD`" 
      type="error" 
      show-icon 
      class="error-message"
      :closable="false" style="margin-bottom: 10px;"
    />
    <el-alert 
      v-if="form.amount % 100 !== 0" 
      :title="$t('message.orderAmountNotMultipleOfHundred')" 
      type="warning" 
      show-icon 
      class="warning-message custom-alert" 
      :close-text="$t('message.adjustAmount')"
      @close="adjustHKD" style="margin-bottom: 10px;"
    />

    <el-alert 
      v-if="form.amount/1 < minAmountForNoFee" 
      :title="`${$t('message.feeApplied', { amount: minAmountForNoFee, fee: 0 })}`" 
      type="warning" 
      show-icon 
      class="warning-message"
      :closable="false" 
    />

    <div class="button-group">
      <el-button type="success" class="usdt-button" @click="openUsdtDrawer" round>
        {{ $t("message.usdtOrHkdAction") }}
      </el-button>
    </div>

    <el-form-item>
      <div style="text-align: left;">{{ $t('message.chain') }}</div>
        <el-select v-model="form.chain" placeholder="Select Chain" >
          <el-option label="Tron / TRC20" value="tron"></el-option>
          <el-option label="Binance Smart Chain / BEP20" value="bsc"></el-option>
          <el-option label="Ethereum / ERC20" value="eth"></el-option>
          <el-option label="Polygon / ERC20" value="pol"></el-option>
          <el-option label="BinancePay / BinPay" value="binpay"></el-option>
          <el-option v-if="allowAdjust" :label="$t('message.binanceOnlineOrder')" value="binance_online"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <div style="text-align: left;">{{ $t('message.walletAddress') }}</div>
        <el-input v-model="form.walletAddress" :placeholder="$t('message.enterWalletAddress')"></el-input>

        <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />
        <qrcode-capture @detect="onDecode" ref="qrcodeCapture" style="display: none;"></qrcode-capture>

        <el-button type="success" @click="triggerFileInput" style="margin-top: 10px;">{{
          t("message.uploadImage")
          }}</el-button>

        <div v-if="qrCodeImage" style="position: relative; display: flex; text-align: center; flex-direction: column; align-items: center;">
          <img :src="qrCodeImage" :style="{ transform: `rotate(${rotation}deg)`, maxWidth: '50%', height: 'auto' }" alt="Uploaded QR Code" />
          <el-button type="primary" @click="rotateImage" style="margin-top: 10px; display: inline-block;">{{ t("message.rotateImage") }}</el-button>
        </div>
      </el-form-item>

    <el-text type="info" size="small">{{ $t('message.largeOrderMessage', { amount: formatNumberWithCommasNoDecimals(largeOrderThreshold), rate: largeExchangeRate }) }}</el-text>

    <div style="text-align: left;">
      <el-tag type="info" style="text-align: left;" v-if="!allowAdjust">
          {{ $t('message.selfServiceOrder') }}
      </el-tag>
    </div>

    <el-drawer
      :title="$t('message.usdtOrHkdAction')"
      v-model="usdtDrawerVisible"
      direction="btt"
      size="40%"
      @open="focusUsdtInput" 
    >
      <el-form label-width="100px">
        <el-form-item :label="$t('message.usdtAmount')">
          <el-input
            ref="usdtInputRef" 
            v-model="usdtInput"
            :placeholder="$t('message.enterUSDT')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.amount')">
          <el-input
            v-model="hkdInput"
            :placeholder="$t('message.amount')"

          ></el-input>
        </el-form-item>
        <el-button type="success" @click="adjustHKD" style="width: 100%">
          {{ t("message.adjustAmount") }}
        </el-button>
      </el-form>
    </el-drawer>

    <div class="submit-section">
      <el-button type="primary" :disabled="isSubmitting" @click="submitForm" class="submit-button">{{
          $t("message.submit")
          }}</el-button>
    </div>
    
  </div>

  <div v-else class="submission-result">
    <div class="receipt">
      <h2>{{ t("message.receiptTitle") }}</h2>
      <div class="result-row">
        <strong class="result-label">{{ t("message.orderNumber") }}:</strong>
        <span class="result-value">{{ orderNumber }}</span>
      </div>
      <div class="result-row">
        <strong class="result-label">{{ t("message.orderType") }}:</strong>
        <span class="result-value">{{ t("message.buyUSDT") }}</span>
      </div>
      <div class="result-row">
        <strong class="result-label">{{ t("message.orderTime") }}:</strong>
        <span class="result-value">{{ formatDateToCustom(convertUTCToLocalTime(orderCreated_at)) }}</span>
      </div>
      <div class="result-row" style="font-size: 24px">
        <strong class="result-label">{{ t("message.amount") }}:</strong>
        <span class="result-value highlight">{{ formatNumberWithCommasNoDecimals(form.amount) }} HKD</span>
      </div>
      <div class="result-row">
        <strong class="result-label">{{ t("message.exchangeRate") }}:</strong>
        <span class="result-value">{{ form.exchangeRate }} HKD/USDT</span>
      </div>
      <div class="result-row">
        <strong class="result-label">{{ t("message.usdtAmount") }}:</strong>
        <span class="result-value highlight">{{ formatNumberWithCommas(form.usdtAmount) }} USDT</span>
      </div>
      
      <div class="result-row">
        <strong class="result-label">{{ t("message.walletAddress") }}:</strong>
        <span class="result-value">{{ form.walletAddress }}</span>
      </div>

      <qrcode-vue :value="form.walletAddress" :size="150"></qrcode-vue>

      <p>
        <strong>{{ t("message.receiptDeclaration") }}:</strong>
      </p>
      <p>{{ t("message.receiptDeclarationContent") }}</p>

      <div class="result-row">
        <strong class="result-label">{{ t("message.printTime") }}:</strong>
        <span class="result-value">{{ formatDateToCustom(new Date()) }}</span>
      </div>

      <el-button type="primary" @click="printReceipt">{{
        t("message.printReceipt")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { validateWalletAddress } from "@/utils/validateWalletAddress";
import { API_BASE_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import QrcodeVue from "vue-qrcode";
import { useRouter } from "vue-router";
import { QrcodeCapture } from 'vue-qrcode-reader'
import { getAllPriceQuotes } from '@/utils/priceQuoteService';

export default {
  components: {
    QrcodeVue,
    QrcodeCapture
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();
    const qrCodeImage = ref("");
    const orderCreated_at = ref();
    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 100);
    const minAmountForNoFee = ref(0); //ref(process.env.VUE_APP_MIN_AMOUNT_FOR_NO_FEE || 20000);
    const hkdFeeAmount = ref(0); // 修改手續費為0

    const form = ref({
      amount: "",
      usdtAmount: "",
      walletAddress: "",
      chain: "tron",
      exchangeRate: "0",
      hkdFee: "0",
    });
    const exchangeRate = ref(null);
    const largeExchangeRate = ref(0);
    const largeOrderThreshold = ref(0);
    const isSubmitted = ref(false);
    const orderNumber = ref("");
    const isScanning = ref(false);
    const video = ref(null);
    const qrcodeCapture = ref(null)
    const isUsdtInputActive = ref(false);
    const isSubmitting = ref(false); // 新增状态变量

    const usdtInput = ref("");
    const hkdInput = ref("");

    const rmbDialogVisible = ref(false);
    const rmbInput = ref("");
    const rmbToHkdRate = ref(0);
    const calculatedHKD = ref(0);
    const currencyType = ref('HKD'); // 默认为港币

    const usdtDrawerVisible = ref(false);
    const rotation = ref(0); // 用于存储图片的旋转角度
    const isImageRotated = ref(false); // 用于跟踪图片是否被旋转

    const buyQuotes = ref([]); // 存储买入档位的价格列表

    // 获取价格列表
    const fetchPriceQuotes = async () => {
      try {
        const response = await getAllPriceQuotes();
        // 过滤出买入档位的价格，并按 sort_order 排序
        buyQuotes.value = response.data
          .filter(quote => quote.direction === 'buy')
          .sort((a, b) => a.sort_order - b.sort_order);
        
        // 初始化默认汇率（使用第一档价格）
        if (buyQuotes.value.length > 0) {
          form.value.exchangeRate = buyQuotes.value[0].price;
          exchangeRate.value = buyQuotes.value[0].price;
        }
      } catch (error) {
        console.error('Error fetching price quotes:', error);
        ElMessage.error(t("message.fetchPriceError"));
      }
    };

    // 监听 form.amount 的变化
    watch(hkdInput, (newAmount) => {
      const amount = parseFloat(newAmount);
      
      if (!isNaN(amount)) {
       
        // 根据计算出的港币金额查找对应档位的价格
        const matchingQuote = buyQuotes.value.filter(quote => 
          amount >= quote.target_volume && quote.is_active
        ).pop(); // 使用 pop() 获取最后一个符合条件的元素
        
        // 如果找到匹配的档位，使用对应的价格
        if (matchingQuote) {
          form.value.exchangeRate = matchingQuote.price;
          console.log(`切换到档位 ${matchingQuote.level}, 价格: ${matchingQuote.price}`);
        } else {
          // 如果没有找到匹配的档位，使用默认的第一档价格
          form.value.exchangeRate = buyQuotes.value[0]?.price || exchangeRate.value;
        }
        
        form.value.amount = amount.toFixed(0);
        usdtInput.value = "";
        calculateUSDT();
        calculateFee();
      }
    });



    function confirmRmbAmount() {
      console.log('Confirmed RMB Amount:', rmbInput.value);
      rmbDialogVisible.value = false;
    }

    const openRmbDialog = () => {
      rmbDialogVisible.value = true;
    };

    onMounted(async () => {
      console.log('props:', props.allowAdjust);
      await fetchPriceQuotes(); // 替换原来的 fetchExchangeRate
    });

    const calculateUSDT = () => {
      if (form.value.exchangeRate && form.value.amount) {
        let hkdAmount = parseFloat(form.value.amount);
        
        if (hkdAmount < minAmountForNoFee.value) {
          hkdAmount -= parseFloat(hkdFeeAmount.value);
        }
        
        hkdAmount = Math.max(hkdAmount, 0);
        
        let usdtAmount = hkdAmount / parseFloat(form.value.exchangeRate);
        form.value.usdtAmount = usdtAmount.toFixed(4);
      }
    };

    const calculateFee = () => {
      if (parseFloat(form.value.amount) < minAmountForNoFee.value) {
        form.value.hkdFee = hkdFeeAmount.value.toString();
      } else {
        form.value.hkdFee = "0";
      }
    };

    // 修改 watch(usdtInput) 的处理逻辑
    watch(usdtInput, (newValue) => {
      if (newValue && form.value.exchangeRate) {
        let hkdAmount = parseFloat(newValue) * parseFloat(form.value.exchangeRate);
        
        // 根据计算出的港币金额查找对应档位的价格
        const matchingQuote = buyQuotes.value.filter(quote => 
          hkdAmount >= quote.target_volume && quote.is_active
        ).pop(); // 使用 pop() 获取最后一个符合条件的元素

        
        if (matchingQuote) {
          form.value.exchangeRate = matchingQuote.price;
          hkdAmount = parseFloat(newValue) * parseFloat(matchingQuote.price);
        }
        
        form.value.amount = hkdAmount.toFixed(0);
        form.value.usdtAmount = newValue;
        hkdInput.value = "";
        calculateFee();
      }
    });

    const validateForm = () => {
      console.log('form:', form.value);
      if (form.value.chain === 'binance_online' || form.value.chain === 'binpay') {
        console.log('binance_online 或 binpay - 不验证地址');
        return true;
      }
      
      // if (!props.allowAdjust) {
      //   if (parseFloat(form.value.amount) % 100 !== 0) {
      //     ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
      //     return false;
      //   }
      // }

      if (parseFloat(form.value.amount) < minAmount.value) {
        ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
        return false;
      }

      const calculatedHKD = parseFloat(form.value.usdtAmount) * parseFloat(exchangeRate.value) + parseFloat(form.value.fee);
      if (Math.abs(calculatedHKD - parseFloat(form.value.amount)) > 0.001) {
        ElMessage.error(t("message.amountMismatchError"));
        return false;
      }

      if (form.value.chain !== 'binance_online') {
        console.log('form.value.walletAddress:', form.value.walletAddress);
        console.log('form.value.chain:', form.value.chain);
        const addressValidation = validateWalletAddress(
          form.value.walletAddress,
          form.value.chain
        );
        if (!addressValidation.valid) {
          ElMessage.error(t(`message.${addressValidation.error}`));
          return false;
        }
      }

      return true;
    };

    const increaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) + 0.001).toFixed(4);
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) - 0.001).toFixed(4);
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm() || isSubmitting.value) return; // 防止重复提交
      isSubmitting.value = true; // 设置为正在提交状态

      orderNumber.value = generateOrderId();

      const imageDataToUpload = isImageRotated.value ? await rotateImageForUpload() : qrCodeImage.value;

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", form.value.usdtAmount);
      formData.append("exchange_rate", form.value.exchangeRate);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "buy");
      formData.append("fee_hkd", form.value.hkdFee);
      formData.append("order_source", props.allowAdjust ? 'in_store' : 'self_service');

      if (imageDataToUpload) {
        const blob = dataURLToBlob(imageDataToUpload);
        formData.append("qr_code_image", blob);
      }

      try {
        const response = await axios.post(
          `${API_BASE_URL}/preorder`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const orderData = response.data.order;

        orderNumber.value = orderData.order_number;
        form.value.amount = orderData.amount;
        form.value.usdtAmount = orderData.usdt_amount;
        exchangeRate.value = orderData.exchange_rate;
        form.value.walletAddress = orderData.receive_address;
        form.value.chain = orderData.chain_type;
        orderCreated_at.value = orderData.created_at;

        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("订单提交失败:", error);
        ElMessage.error(t("message.submitFailed"));
      } finally {
        isSubmitting.value = false; // 重置提交状态
      }
    };

    const rotateImageForUpload = () => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = qrCodeImage.value;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.translate(canvas.width, canvas.height);
          ctx.rotate(Math.PI);
          ctx.drawImage(img, 0, 0);
          const rotatedImageData = canvas.toDataURL('image/jpeg');
          resolve(rotatedImageData);
        };
      });
    };

    const dataURLToBlob = (dataURL) => {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    };

    const goToHome = () => {
      form.value = {
        amount: "",
        walletAddress: "",
        chain: "tron",
      };
      usdtInput.value = "";
      hkdInput.value = "";
      isSubmitted.value = false;
    };

    const adjustHKD = () => {
      if (form.value.amount) {
        const adjustedAmount = Math.floor(parseFloat(form.value.amount) / 100) * 100;
        form.value.amount = adjustedAmount;
        hkdInput.value = adjustedAmount;
      }
    };

    const viewReceipt = () => {
      router.push({
        name: "ReceiptBuy",
        params: {
          orderNumber: orderNumber.value,
        },
      });
    };

    const fileInput = ref(null);

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const onFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          qrCodeImage.value = e.target.result;
          rotation.value = 0; // 重置旋转角度
        };
        reader.readAsDataURL(file);

        await nextTick();
        if (qrcodeCapture.value && qrcodeCapture.value.$el) {
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          qrcodeCapture.value.$el.files = dataTransfer.files;
          qrcodeCapture.value.$el.dispatchEvent(new Event('change', { bubbles: true }));
        }
      }
    };

    const triggerQrcodeCapture = () => {
      if (qrcodeCapture.value) {
        const inputElement = qrcodeCapture.value.$el
        if (inputElement && inputElement.tagName === 'INPUT') {
          inputElement.click()
        }
      }
    }

    const printReceipt = () => {
      router.push({ name: "PrintReceipt", params: { orderNumber: orderNumber.value } });
    };

    function formatDateToCustom(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function convertUTCToLocalTime(utcTimeString) {
      const utcDate = new Date(utcTimeString);
      return utcDate.toLocaleString("zh-CN", {
        timeZone: "Asia/Hong_Kong",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }

    const onDecode = (content) => {
      const decodedContent = content[0].rawValue;
      console.log('解码的二维码内容:', decodedContent);
      const match = decodedContent.match(/^(ethereum:)?(0x[0-9a-fA-F]{40})/);
      console.log('match:', match);
      if (match) {
        form.value.walletAddress = match[2];
        form.value.chain = 'eth';
        console.log('检测到以太坊地址:', form.value.walletAddress);
        console.log('设置链为:', form.value.chain);
      } else {
        form.value.walletAddress = decodedContent;
        console.log('使用原始地址:', form.value.walletAddress);
      }
    };

    const handleUsdtFocus = () => {
      isUsdtInputActive.value = true;
    };

    const handleUsdtBlur = () => {
      isUsdtInputActive.value = false;
    };

    const openUsdtDrawer = () => {
      usdtDrawerVisible.value = true;
    };

    const rotateImage = () => {
      rotation.value = (rotation.value + 180) % 360;
      isImageRotated.value = true;
    };

    return {
      form,
      exchangeRate,
      isSubmitted,
      orderNumber,
      submitForm,
      t,
      formatNumberWithCommas,
      goToHome,
      formatNumberWithCommasNoDecimals,
      adjustHKD,
      viewReceipt,
      minAmount,
      qrCodeImage,
      triggerQrcodeCapture,
      isScanning,
      video,
      printReceipt,
      orderCreated_at,
      formatDateToCustom,
      convertUTCToLocalTime,
      increaseExchangeRate,
      decreaseExchangeRate,
      onDecode,
      qrcodeCapture,
      triggerFileInput,
      onFileChange,
      fileInput,
      minAmountForNoFee,
      hkdFeeAmount,
      handleUsdtFocus,
      handleUsdtBlur,
      usdtInput,
      hkdInput,
      rmbDialogVisible,
      rmbInput,
      calculatedHKD,
      openRmbDialog,
      confirmRmbAmount,
      rmbToHkdRate,
      currencyType,
      largeExchangeRate,
      largeOrderThreshold,
      openUsdtDrawer,
      usdtDrawerVisible,
      rotation,
      rotateImage,
      isSubmitting, // 返回新的状态变量
    };
  },
};
</script>

<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.buy-page {
  margin: 20px;
  max-width: 600px; /* 设置最大的宽度 */
  margin-left: auto; /* 居中对齐 */
  margin-right: auto; /* 居中对齐 */
  margin-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
}
.buy-page .el-button {
  margin-left: 0px;
}

@media print {
  body {
    width: 57mm;
    margin: 0;
    padding: 0;
  }

  .receipt {
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .no-print,
  button,
  .el-button {
    display: none !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.fee-message {
  color: #E6A23C;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
}

.submit-section .el-button {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  margin-left: 0px;
  margin-right: 0px;
}

.submission-result {
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.result-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.result-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.result-value {
  font-size: 16px;
  color: #666;
}

.hkd-value, .usdt-value {
  font-size: 36px; /* 调大字体 */
  font-weight: bold;
  color: #000;
}

.decimal-value {
  font-size: 24px; /* 调整小数部分的字体大小 */
  font-weight: normal;
  color: #6c757d;
}

.result-value.highlight {
  font-size: 24px; /* 调大字体 */
  font-weight: bold;
  color: #000;
}

.order-hint {
  margin-top: 20px;
  font-size: 12px;
  color: #FFC107;
  text-align: center;
}

.submit-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important; /* 确保按钮与页面等宽 */
  height: 50px !important; /* 使用 !important 确保样式生效 */
  font-size: 22px; /* 调整字体大小 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #409eff; /* 按钮背景色 */
  color: white; /* 按钮文字颜色 */
  border: none;
  border-radius: 0; /* 去掉圆角 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  z-index: 1000; /* 确保按钮在最上层 */
  margin: 0; /* 确保没有额外的外边距 */
}
</style>
